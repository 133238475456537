<template>
	<div class="relative z-50 w-full h-screen flex justify-center items-center">
		<button class="absolute z-50 top-0 right-0 m-10">
			<i class="fad fa-times fa-2x fa-spin text-red-500"></i>
		</button>
		<div ref="modal" class="w-full h-full bg-contain bg-no-repeat bg-center" :style="image.image"></div>
		<div class="absolute bottom-0 pt-60 pb-40 w-full text-center bg-gradient-to-b from-transparent to-asphalto">
			<h1 class="border-t border-red-500 p-4 absolute lg:w-1/2 lg:mx-auto mx-10 bottom-20 left-0 right-0">{{ image.description }}</h1>
		</div>
	</div>
</template>

<script>
	import anime from "animejs";
	export default {
		props: {
			image: {
				required: true,
			},
		},
		mounted() {
			anime({
				// <-- using imported
				targets: this.$refs.modal,
				opacity: [0, 1],
				scale: [0.25, 1],
				easing: "easeInOutQuad",
				duration: 1000,
			});
		},
	};
</script>

<style lang="scss" scoped></style>
