<template>
	<div id="portraits" class="relative mx-auto px-6">
		<h1 class="text-right lg:mr-20 lg:text-5xl text-3xl tracking-widest">Portraits<span class="text-red-500 text-5xl">.</span></h1>

		<div class="album grid grid-rows-12 grid-flow-col md:gap-4 gap-4 min-h-screen -mt-10">
			<div id="card" class="row-start-1 row-span-2 col-start-1 col-span-2">
				<Card @counter="counter" :image="images[0]"></Card>
			</div>
			<div id="card" class="row-start-2 row-span-2 col-start-3 col-span-2">
				<Card @counter="counter" :image="images[1]"></Card>
			</div>
			<div id="card" class="row-start-3 row-span-2 lg:col-start-5 col-start-1 col-span-2">
				<Card @counter="counter" :image="images[2]"></Card>
			</div>
			<div id="card" class="row-start-4 row-span-2 col-start-3 col-span-2">
				<Card @counter="counter" :image="images[3]"></Card>
			</div>
			<div id="card" class="row-start-5 row-span-2 col-start-1 col-span-2">
				<Card @counter="counter" :image="images[4]"></Card>
			</div>
		</div>

		<div v-if="number >= 0 && number <= 4" @click="number = 10" class="z-40 left-0 top-0 bottom-0 right-0 w-full h-full fixed bg-black bg-opacity-75 transform opacity-100 duration-1000">
			<div class="relative p-10 z-50 w-full h-screen flex justify-center items-center">
				<button @click="number = 10" class="absolute top-0 right-0 m-10">
					<i class="fad fa-times fa-2x fa-spin text-red-500"></i>
				</button>

				<div class="w-full h-full bg-contain bg-no-repeat bg-center" :style="imagePath[number].image"></div>

				<div class="absolute bottom-0 pt-60 pb-40 w-full text-center bg-gradient-to-b from-transparent to-asphalto">
					<h1 class="border border-red-500 p-4 absolute lg:w-1/2 lg:mx-auto mx-10 bottom-20 left-0 right-0">{{ imagePath[number].description }}</h1>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Card from "../card";
	import gsap from "gsap";
	import { ScrollTrigger } from "gsap/ScrollTrigger";
	gsap.registerPlugin(ScrollTrigger);

	export default {
		mounted: function () {
			ScrollTrigger.batch("#card", {
				onEnter: (elements) => {
					gsap.from(elements, {
						autoAlpha: 0,
						yPercent: 200,
						ease: "power2.out",
						duration: 1.5,
					});
				},
				once: true,
			});
		},

		components: {
			Card,
		},
		data() {
			return {
				number: 10,
			};
		},
		methods: {
			counter(numero) {
				this.number = numero;
				this.$emit("counter", this.number);
			},
		},

		props: {
			images: {},
		},
	};
</script>
