<template>
	<div id="environment" class="relative mx-auto px-6">
		<h1 class="lg:ml-20 lg:text-5xl text-3xl tracking-widest">Oikos<span class="text-red-500 text-5xl">.</span></h1>

		<div class="grid grid-rows-12 grid-flow-col md:gap-4 gap-4 min-h-screen -mt-10">
			<div id="card" class="row-start-1 row-span-2 lg:col-start-5 col-start-3 col-span-2">
				<Card @counter="counter" :image="images[5]"></Card>
			</div>
			<div id="card" class="row-start-2 row-span-2 lg:col-start-3 col-start-1 col-span-2">
				<Card @counter="counter" :image="images[6]"></Card>
			</div>
			<div id="card" class="row-start-3 row-span-2 lg:col-start-1 col-start-3 col-span-2">
				<Card @counter="counter" :image="images[7]"></Card>
			</div>
			<div id="card" class="row-start-4 row-span-2 lg:col-start-3 col-span-2">
				<Card @counter="counter" :image="images[8]"></Card>
			</div>
			<div id="card" class="row-start-5 row-span-2 lg:col-start-5 col-span-2">
				<Card @counter="counter" :image="images[9]"></Card>
			</div>
		</div>
	</div>
</template>

<script>
	import Card from "../card";

	export default {
		components: { Card },
		data() {
			return {
				number: 10,
			};
		},
		methods: {
			counter(numero) {
				this.number = numero;
				this.$emit("counter", this.number);
			},
		},
		props: {
			images: {},
		},
		mounted: function () {},
	};
</script>
