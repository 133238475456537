<template>
	<section id="contact" class="text-gray-400 relative h-full">
		<div class="h-96 w-full lg:h-screen bg-asphalto">
			<iframe
				title="map"
				width="100%"
				height="100%"
				frameborder="0"
				marginheight="0"
				marginwidth="0"
				scrolling="yes"
				src="https://maps.google.com/maps?q=camerino&t=&z=13&ie=UTF8&iwloc=&output=embed"
				style="filter: grayscale(1) contrast(1.2) opacity(0.7)"
			></iframe>
		</div>
		<div class="lg:absolute top-20 right-20 lg:w-1/3 mt-10 px-5 md:px-0 lg:mt-0 max-w-lg mx-auto">
			<form method="post" action="#" class="border border-gray-700 bg-asphalto rounded-lg p-8 flex flex-col gap-5">
				<h1 class="text-white text-lg font-medium">Feedback</h1>
				<p>Lorem ipsum dolor sit amet consec tetur adipisicing elit.</p>
				<label for="email" class="text-sm"
					>Email <input type="email" name="email" class="bg-gray-900 rounded border w-full border-gray-700 focus:border-red-500 text-base outline-none t py-1 px-3"
				/></label>
				<label for="message" class="text-sm"
					>Message
					<textarea type="text" name="message" class="w-full bg-gray-900 rounded border border-gray-700 focus:border-red-500 h-32 text-base outline-none py-1 px-3 resize-none"></textarea>
				</label>
				<button class="text-base text-white text-center w-full p-3 border-red-500 hover:bg-red-500 border-2">Send</button>
				<p class="text-xs text-opacity-90">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dignissimos voluptatibus consectetur eos eius corrupti.</p>
			</form>
		</div>
	</section>
</template>

<script>
	export default {};
</script>

<style lang="scss" scoped></style>
