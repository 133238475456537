<template>
	<div class="h-screen w-full flex flex-col justify-center items-center">
		<div class="flex flex-col justify-center items-center gap-8 lg:text-6xl text-5xl">
			<h1 id="text" class="font-semibold tracking-wider">BACK IT UP<span class="text-red-500 font-bold">.</span></h1>
			<h1 id="text1" class="text-red-500 font-light tracking-wider">GET IT BACK<span class="text-white font-bold">.</span></h1>
			<a id="button" v-smooth-scroll="{ duration: 1000 }" href="#portraits" class="text-base text-center w-full p-3 border-2 border-red-500 hover:bg-red-500">Portraits</a>
		</div>
	</div>
</template>

<script>
	import { gsap } from "gsap";
	import { TextPlugin } from "gsap/TextPlugin";

	gsap.registerPlugin(TextPlugin);

	export default {
		mounted() {
			var tl = gsap.timeline({ delay: 1 });
			tl.from("#image", { duration: 2, opacity: 0 });
			tl.from("#icon", { duration: 2, y: 300, opacity: 0.1, scale: 0, stagger: 0.25 }, "=-2");
			tl.from("#text", { duration: 1, text: "" });
			tl.from("#text1", { duration: 1, text: "" });
			tl.from("#button", { duration: 1, opacity: 0, ease: "bounce.out", scale: 0.5 });
		},
	};
</script>

<style lang="scss" scoped></style>
