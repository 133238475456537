<template>
	<div class="relative bg-asphalto">
		<div class="home"><Navbar class="absolute z-50"></Navbar></div>
		<div class="grid lg:grid-cols-12 relative">
			<div class="lg:col-start-1 lg:col-span-5 col-span-1 lg:static absolute z-40 w-full flex justify-center items-center">
				<MainPageContent></MainPageContent>
			</div>
			<div class="lg:col-start-7 lg:col-span-5 col-span-1 relative">
				<MainPageImage></MainPageImage>
			</div>
		</div>
		<div class="mt-40"><SecondPage @counter="counter" :images="images"></SecondPage></div>
		<div class="mt-40"><ThirdPage @counter="counter" :images="images"></ThirdPage></div>
		<div class="-pt-20"><FourthPage></FourthPage></div>
		<div class="py-20"><FifthPage></FifthPage></div>
		<div class=""><Position></Position></div>
		<div class=""><Footer></Footer></div>

		<div v-if="number >= 0 && number <= 10" @click="number = 1000" class="z-40 left-0 top-0 bottom-0 right-0 w-full h-full fixed bg-black bg-opacity-75">
			<Modal :image="images[number]"></Modal>
		</div>
	</div>
</template>

<script>
	import Modal from "../components/modal";
	import Footer from "../components/footer";
	import Position from "../components/position/position";
	import FifthPage from "../components/fifthPage/fifthPage";
	import FourthPage from "../components/fourthPage/fourthPage";
	import ThirdPage from "../components/thirdPage/thirdPage";
	import SecondPage from "../components/secondPage/secondPage";
	import MainPageContent from "../components/mainPage/mainPageContent";
	import MainPageImage from "../components/mainPage/mainPageImage";
	import Navbar from "../components/navbar";
	// @ is an alias to /src

	export default {
		name: "Home",
		components: {
			Modal,
			Footer,
			Position,
			FifthPage,
			FourthPage,
			ThirdPage,
			SecondPage,
			MainPageContent,
			MainPageImage,
			Navbar,
		},
		data() {
			return {
				images: [
					{
						id: 1,
						image:
							'background-image: url("https://media.istockphoto.com/photos/closeup-of-man-praying-picture-id628648170?b=1&k=20&m=628648170&s=170667a&w=0&h=jMYw2xL5h5mSPWst5pyA3EHtCAcyIN9oe1gO9QOC-hk=") ',
						title: "The Illusionist",
						description: "	Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime, beatae!",
					},
					{
						id: 2,
						image:
							'background-image: url("https://media.istockphoto.com/photos/portrait-of-a-woman-picture-id1278529171?b=1&k=20&m=1278529171&s=170667a&w=0&h=Cq9D86QVlYuOG_jl0oSO2_xwuyDbKrduiYJ52yFN7Tg=") ',
						title: "The Illusionist",
						description: "	Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime, beatae!",
					},
					{
						id: 3,
						image:
							'background-image: url("https://media.istockphoto.com/photos/black-white-tiger-picture-id954560222?b=1&k=20&m=954560222&s=170667a&w=0&h=Tlcoe6sN_cSs1X0UXxfCt6Cvb88wW-HolHshnGhbQ5A=") ',
						title: "The Illusionist",
						description: "	Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime, beatae!",
					},

					{
						id: 4,
						image:
							'background-image: url("https://media.istockphoto.com/photos/confident-portrait-of-a-black-woman-picture-id623705146?b=1&k=20&m=623705146&s=170667a&w=0&h=2BGNqXsaqb8lbT_UNQTjrHzCwak9OA7xx8RBq7WAq3E=") ',
						title: "The Illusionist",
						description: "	Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime, beatae!",
					},
					{
						id: 5,
						image:
							'background-image: url("https://images.unsplash.com/photo-1508186225823-0963cf9ab0de?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8cG9ydHJhaXRzJTIwYmxhY2slMjBhbmQlMjB3aGl0ZXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=400&q=60") ',
						title: "The Illusionist",
						description: "	Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime, beatae!",
					},
					{
						id: 6,
						image:
							'background-image: url("https://media.istockphoto.com/photos/spring-snow-showers-in-the-alps-picture-id525961908?b=1&k=20&m=525961908&s=170667a&w=0&h=C9I0OzZx6D3Ilg8qfVQwvLxCVG3ZwCtC2dIowcz9jiU=") ',
						title: "The Shadow",
						description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime, beatae!",
					},
					{
						id: 7,
						image:
							'background-image: url("https://media.istockphoto.com/photos/dandelion-seed-with-water-drops-picture-id143174573?b=1&k=20&m=143174573&s=170667a&w=0&h=tTM9DKzzODC2lHAhyLCfdRwq3yUGbMPkUFZ4wOjzOEU=") ',
						title: "The Shadow",
						description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime, beatae!",
					},
					{
						id: 8,
						image: 'background-image: url("https://media.istockphoto.com/photos/lonely-tree-picture-id1272702144?b=1&k=20&m=1272702144&s=170667a&w=0&h=LMBR0UsiEqzmcC1QCGSa0_PmY5vd1uXQeQCTJCjelqg=") ',
						title: "The Shadow",
						description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime, beatae!",
					},

					{
						id: 9,
						image:
							'background-image: url("https://media.istockphoto.com/photos/common-dandelion-blowball-artistic-detail-of-soft-fluff-taraxacum-picture-id1148830518?b=1&k=20&m=1148830518&s=170667a&w=0&h=GnZzSu3-lNV_mqpSZJXDY_6g6GoIBxBo33oUVtfW0PY=") ',
						title: "The Shadow",
						description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime, beatae!",
					},
					{
						id: 10,
						image:
							'background-image: url("https://images.unsplash.com/photo-1566092977178-7b669b289a69?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8bmF0dXJlJTIwYmxhY2slMjBhbmQlMjB3aGl0ZXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=400&q=60") ',
						title: "The Shadow",
						description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime, beatae!",
					},
				],
				number: 1000,
			};
		},
		methods: {
			counter(numero) {
				this.number = numero;
			},
		},
	};
</script>
