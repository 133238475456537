<template>
	<div id="aboutme" class="flex w-full relative">
		<div class="lg:w-2/3 w-full relative">
			<div
				class="h-screen w-full bg-cover lg:bg-bottom bg-center"
				style="
					background-image: url('https://images.unsplash.com/photo-1598505873864-5930c81fe064?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NzB8fHBvcnRyYWl0cyUyMGJsYWNrJTIwYW5kJTIwd2hpdGV8ZW58MHx8MHx8&auto=format&fit=crop&w=400&q=60');
				"
			>
				<div class="absolute w-full h-full bg-gradient-to-r from-asphalto via-transparent to-asphalto"></div>
				<div class="absolute z-10 w-full h-full bg-gradient-to-t from-asphalto via-transparent to-asphalto"></div>
			</div>
		</div>
		<div class="lg:w-1/2 w-full z-20 absolute left-0 right-0 mx-auto top-40 lg:static flex flex-col justify-center">
			<div class="lg:text-6xl text-5xl mx-auto">
				<h1 class="font-semibold tracking-wider">LET'S DO THIS</h1>
				<h1 class="mt-5 text-red-500 font-extralight text-center tracking-wider">TOGETHER<span class="text-white font-bold text-5xl">.</span></h1>
			</div>
		</div>
	</div>
</template>

<script>
	export default {};
</script>
