<template>
	<div @click="$emit('counter', image.id - 1)" class=" bg-cover bg-center z-30 relative w-full h-full group cursor-pointer" :style="image.image">
		<div class=" absolute bottom-0 lg:pl-10 pl-2 w-full h-full bg-black bg-opacity-20 hover:bg-opacity-75 transform duration-1000">
			<h1 class="absolute lg:bottom-14 bottom-10 lg:text-2xl text-xl lg:transform group-hover:-translate-y-20 duration-500">{{ image.title }}</h1>
			<hr class="border-4 rounded-full border-red-500 absolute lg:bottom-8 bottom-5 w-16 lg:transform group-hover:-translate-y-20 duration-300" />
			<span class="lg:block hidden absolute bottom-6 opacity-0 lg:text-xl text-base group-hover:opacity-80 transform duration-1000">
				{{ image.description }}
			</span>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			image: {},
		},
		data() {
			return {};
		},
		methods: {},
	};
</script>
<style scoped>
	/* .box {
		opacity: 0;
		transition: all 1s;
		transform: translateY(30vh);
	}
	.box.in-viewport {
		opacity: 1;
		transform: translateY(0vh);
	} */
</style>
