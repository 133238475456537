<template>
	<div class="relative w-full">
		<div class="absolute z-30 w-full h-full bg-gradient-to-r from-asphalto via-transparent to-asphalto"></div>
		<div class="absolute z-10 w-full h-full bg-gradient-to-t from-asphalto via-transparent to-asphalto"></div>

		<div id="image" class="h-screen bg-cover bg-center" :style="image"></div>

		<div class="absolute w-full z-50 bottom-20 flex justify-center gap-8 left-0 right-0">
			<i id="icon" class="fab fa-twitter fa-lg text-red-500"></i>
			<i id="icon" class="fab fa-instagram fa-lg text-red-500"></i>
			<i id="icon" class="fab fa-facebook-f fa-lg text-red-500"></i>
			<i id="icon" class="far fa-envelope fa-lg text-red-500"></i>
		</div>
	</div>
</template>

<script>
	export default {
		mounted() {},
		data() {
			return {
				image:
					"background-image: url('https://images.unsplash.com/photo-1552168324-d612d77725e3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8Y2FtZXJhfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=400&q=60')",
			};
		},
	};
</script>
