<template>
	<div class="w-full h-20 lg:px-20 p-5 flex flex-col lg:items-start items-end justify-center">
		<button v-if="menu" @click="menu = !menu"><i class="fad fa-times fa-2x text-red-500"></i></button>
		<button v-else @click="menu = !menu"><i class="fad fa-bars fa-2x text-red-500"></i></button>
		<transition name="fade">
			<div @click="menu = false" v-if="menu" class="absolute z-50 top-20 left-0 lg:w-1/2 w-full bg-asphalto h-screen flex flex-col items-center gap-10 pt-10">
				<a href="#portraits" v-smooth-scroll="{ duration: 1000 }" class="text-base text-center w-1/2 p-3 border-2 border-red-500 duration-300 hover:bg-red-500">Portraits</a>
				<a href="#environment" v-smooth-scroll="{ duration: 2500 }" class="text-base text-center w-1/2 p-3 border-2 border-red-500 duration-300 hover:bg-red-500">Environment</a>
				<a href="#aboutme" v-smooth-scroll="{ duration: 3000 }" class="text-base text-center w-1/2 p-3 border-2 border-red-500 duration-300 hover:bg-red-500">About Me</a>
				<a href="#contact" v-smooth-scroll="{ duration: 3000 }" class="text-base text-center w-1/2 p-3 border-2 border-red-500 duration-300 hover:bg-red-500">Contact Me</a>
			</div>
		</transition>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				menu: false,
			};
		},
		methods: {
			show() {
				if (this.menu == true) {
					this.menu = false;
				}
			},
		},
	};
</script>
<style scoped>
	.fade-enter-active,
	.fade-leave-active {
		transition: all 0.5s ease-in-out;
	}
	.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
		transform: translateX(-700px);
		opacity: 0;
	}
</style>
