<template>
	<div id="ciao" class="lg:h-screen h-full container mx-auto flex flex-col lg:px-20 p-10 lg:justify-evenly gap-10">
		<div class="lg:flex items-center">
			<div class="text-center lg:w-1/2">
				<h1 class="title1 font-semibold tracking-wider lg:text-5xl text-4xl">BACK IT UP<span class="text-red-500 font-bold text-5xl">.</span></h1>
				<h1 class="text1 text-sm mt-5">Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident repellendus animi quis doloribus soluta voluptatibus doloremque dolorem inventore</h1>
			</div>
			<div id="img1" class="lg:w-1/3 mx-auto lg:block hidden">
				<div
					class="bg-cover h-80 w-full bg-left relative"
					style="
						background-image: url('https://media.istockphoto.com/photos/large-photo-studio-with-professional-lighting-picture-id918337964?b=1&k=20&m=918337964&s=170667a&w=0&h=cFFZqxxLw_PmAAGA5RPZd3pYpSyW3G0B4q6U0U9KBUc=');
					"
				>
					<div class="absolute w-full h-full bg-gradient-to-r from-asphalto via-transparent to-asphalto"></div>
					<div class="absolute w-full h-full bg-gradient-to-t from-asphalto via-transparent to-asphalto"></div>
				</div>
			</div>
		</div>

		<div id="img1" style="clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 0% 50%)" class="lg:hidden mx-auto transform -rotate-90">
			<div
				class="bg-cover h-72 w-72 bg-center transform rotate-90 relative"
				style="
					background-image: url('https://media.istockphoto.com/photos/large-photo-studio-with-professional-lighting-picture-id918337964?b=1&k=20&m=918337964&s=170667a&w=0&h=cFFZqxxLw_PmAAGA5RPZd3pYpSyW3G0B4q6U0U9KBUc=');
				"
			>
				<div class="absolute w-full h-full bg-asphalto opacity-30"></div>
			</div>
		</div>
		<div id="img2" style="clip-path: polygon(100% 0%, 75% 50%, 100% 100%, 25% 100%, 0% 50%, 25% 0%)" class="lg:hidden mx-auto lg:-mt-36 -mt-24 transform -rotate-90">
			<div
				class="bg-cover h-72 w-72 bg-top transform rotate-90 relative"
				style="
							background-image: url('https://media.istockphoto.com/photos/model-posing-for-a-photo-shoot-picture-id867043508?b=1&k=20&m=867043508&s=170667a&w=0&h=kpoMFhgmFk-NHNxkkhOx2lOiq66sPRLFX7vyHTNYGz4=
						"
			>
				<div class="absolute w-full h-full bg-asphalto opacity-30"></div>
			</div>
		</div>
		<div class="lg:flex-row-reverse lg:flex items-center">
			<div class="text-center lg:w-1/2">
				<h1 class="title2 text-red-500 font-light tracking-wider lg:text-5xl text-4xl">GET IT BACK<span class="text-white font-bold text-5xl">.</span></h1>
				<h1 class="text2 text-sm mt-5">Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident repellendus animi quis doloribus soluta voluptatibus doloremque dolorem inventore</h1>
			</div>
			<div id="img2" class="lg:w-1/3 mx-auto lg:block hidden">
				<div
					class="bg-cover h-80 w-full bg-center relative"
					style="
							background-image: url('https://media.istockphoto.com/photos/model-posing-for-a-photo-shoot-picture-id867043508?b=1&k=20&m=867043508&s=170667a&w=0&h=kpoMFhgmFk-NHNxkkhOx2lOiq66sPRLFX7vyHTNYGz4=
						"
				>
					<div class="absolute w-full h-full bg-gradient-to-r from-asphalto via-transparent to-asphalto"></div>
					<div class="absolute w-full h-full bg-gradient-to-t from-asphalto via-transparent to-asphalto"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import gsap from "gsap";
	import { ScrollTrigger } from "gsap/ScrollTrigger";
	gsap.registerPlugin(ScrollTrigger);
	export default {
		mounted: function () {
			ScrollTrigger.batch("#ciao", {
				onEnter: () => {
					var tl = gsap.timeline({ delay: 1 });
					tl.from(".title1", { duration: 1, text: "" });
					tl.from(".text1", { duration: 1, text: "" }, "=-0.01");
					tl.from("#img1", { duration: 1, opacity: 0, y: -100 });
					tl.from("#img2", { duration: 1, opacity: 0, y: 100, ease: "bounce.out" });
					tl.from(".title2", { duration: 1, text: "" });
					tl.from(".text2", { duration: 1, text: "" });
				},
				once: true,
			});
		},
	};
</script>
